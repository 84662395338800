<template>
  <section>
    <notifications/>
    <confirmation-dialogues ref="confirms"/>
    <important-notification />
    <global-alerts ref="alerts"/>
    <router-view :key="$store.state.initTime"/>
    <flood-modal />
  </section>
</template>

<script>
import config from './config';
import ImportantNotification from '@/views/Notifications/ImportantNotification.vue';
import FloodModal from '@/components/FloodModal.vue';

export default {
  name: 'App',
  components: { FloodModal, ImportantNotification },
  data() {
    return {};
  },
  watch: {
    '$i18n.locale': function () {
      this.headersLocale();
    },
    $route(to, from) {
      document.title = `${this.$t(to.name)} - ${this.$config.pageTitle}`;
    },
  },
  mounted() {
    document.title = `${this.$t(this.$route.name)} - ${this.$config.pageTitle}`;
    document.body.setAttribute('data-route', this.$router.currentRoute.name);
  },
  updated() {
    document.body.setAttribute('data-route', this.$router.currentRoute.name);
  },
  created() {
    document.body.setAttribute('data-theme', this.$config.theme);
    if (localStorage.getItem('language') === null) {
      const langs = Object.keys(this.$i18n.messages);
      const locLang = window.navigator.language.substr(0, 2).toLowerCase();
      this.$locale = langs.includes(locLang) ? locLang : 'en';
      localStorage.setItem('language', this.$locale);
    } else {
      this.$locale = localStorage.getItem('language');
    }
    this.headersLocale();
    document.body.setAttribute('data-language', this.$locale);
    moment.locale(this.$locale);
  },
  methods: {
    headersLocale() {
      document.body.setAttribute('data-language', this.$locale);
      localStorage.setItem('language', this.$locale);
      this.$api.affiliates.defaults.headers.common['X-Selected-Locale'] = this.$locale || 'en';
      this.$api.misc.defaults.headers.common['X-Selected-Locale'] = this.$locale || 'en';
      this.$api.affiliates.defaults.headers.common['X-Customer-Id'] = config.customerId;
      this.$api.misc.defaults.headers.common['X-Customer-Id'] = config.customerId;
      document.title = `${this.$t(this.$route.name)} - ${this.$config.pageTitle}`;
      if (this.$moment) {
        this.$moment.locale(this.$locale);
      }

      setTimeout(() => {
        moment.locale(this.$locale);
      }, 300);
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/common/index';
</style>
