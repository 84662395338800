<template>
  <transition name="fade">
    <div
      v-if="isFlood"
      class="flood-modal"
    >
      <div class="popup">
        <div class="body">
          <i class="fas fa-times-circle flood-icon" />
          <div class="title">
            {{ $t('alerts.floodDetected.title') }}
          </div>
          <div class="msg">
            {{ floodMessage }}
          </div>
          <div v-show="floodNextRetry" class="time">
            {{ floodNextRetry }} {{ $t('offers.cardOffers.seconds') }}...
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'FloodModal',
  data() {
    return {
      isFlood: false,
      floodMessage: '',
      floodNextRetry: 0,
    };
  },
  created() {
    this.$eventBus.$on('flood-warning', (data) => {
      clearInterval(window.floodRetryInterval);
      this.isFlood = true;
      this.floodNextRetry = data.payload.seconds_to_wait_before_next_retry;
      this.floodMessage = data.message;
      window.floodRetryInterval = setInterval(() => {
        this.floodNextRetry -= 1;
        if (this.floodNextRetry <= 0) {
          this.isFlood = false;
          clearInterval(window.floodRetryInterval);
        }
      }, 1000);
    });
  },
};
</script>
<style lang="scss" scoped>
.flood-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999999;
  background-color: rgba(0, 0, 0, 0.3);

  &.fade-enter-active, &.fade-leave-active{
    transition: all .4s;
    .wrapper{
      transition: all .4s;
    }
  }
  &.fade-enter, &.fade-leave-to{
    opacity: 0;
    .wrapper{
      transform: translateY(20px);
    }
  }

  .popup {
    position: absolute;
    z-index: 999999;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    border-radius: 8px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    width: 360px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.09);

    .body {
      padding: 40px;

      .flood-icon {
        font-size: 64px;
        color: var(--red-color);
      }

      .title {
        font-size: 20px;
        font-weight: 600;
        margin-top: 16px;
      }

      .msg {
        font-size: 14px;
        margin-top: 8px;
      }

      .time {
        font-size: 16px;
        font-weight: 600;
        margin-top: 16px;
        color: var(--main-color);
      }
    }
  }

  .wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 99999;
    width: 100% !important;
  }
}
</style>
